import { set } from 'lodash';
import { defineStore } from 'pinia';

export const useFilterUser = defineStore(
  'filterUser',
  () => {
    const defaultValues = {
      place_id: '',
      match_all_requirements: '',
      gender: [],
      age_between: [],
      languages_spoken: [],
      services_offered: [],
    };
    const options = ref(defaultValues);

    const setOptions = (params: Record<string, any>) => {
      Object.keys(params).forEach((key) => {
        set(options.value, key, params[key]);
      });
    };

    const resetFilterOptions = () => {
      options.value = defaultValues;
    };

    return { options, setOptions, resetFilterOptions };
  },
  { persist: true }
);
